import React, { useState } from "react";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

let recaptchaRef;
const Result = (props) => {
  return (
    <p className={props.class}>
      {props.message}
    </p>
  );
};

function ContactForm({ props, formStyle }) {
  const [result, showresult] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [notification, showNotification] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    if (!captcha) {
      showNotification(true);
      setTimeout(() => {
        showNotification(false);
      }, 5000);
      console.log("recaptcha")
      return
    }

    emailjs
      .sendForm(
        "amazon",
        "deluxe_contact_form",
        e.target,
        "user_1VlbhGQvs8fOELoEUv8LP"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    recaptchaRef.reset();
    showresult(true);
    showNotification(false);
    setCaptcha(false);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
      <div className="form-group">
        <input type="text" name="fullname" placeholder="Your Name" required />
      </div>

      <div className="form-group">
        <input type="email" name="email" placeholder="Email Address" required />
      </div>

      <div className="form-group">
        <input type="text" name="subject" placeholder="Subject" required />
      </div>

      <div className="form-group">
        <textarea name="message" placeholder="Your Message" required></textarea>
      </div>

      <div className="form-group">
        <ReCAPTCHA
          sitekey="6LeLixgdAAAAANjbBbOAHBMBtx6Ac3nAgpTBqYn8"
          onChange={() => setCaptcha(true)}
          ref={el => { recaptchaRef = el; }}
        />
      </div>

      <div className="form-group">
          <button className="btn-default btn-large">Submit</button>
      </div>

      <div className="form-group">{notification ? <Result class="warning-message" message="Please fill out the Recaptcha" /> : null}</div>

      <div className="form-group">{result ? <Result class="success-message" message="Your message was sent successfully! We will contact you soon." /> : null}</div>
      
    </form>
  );
}
export default ContactForm;
