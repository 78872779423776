import {FiFacebook, FiInstagram, FiLinkedin, FiYoutube} from "react-icons/fi";

const OurSocials = () => {
    return (
        <ul className="social-icon social-default with-gradient">
            <li><a href="https://www.facebook.com/deluxeeditsofficial-105011231992639" target="_blank" rel="noreferrer"><FiFacebook /></a></li>
            <li><a href="https://www.instagram.com/deluxeeditsofficial/" target="_blank" rel="noreferrer"><FiInstagram /></a></li>
            <li><a href="https://www.youtube.com/channel/UCySHY-hDICvYtDqwkWqonFg" target="_blank" rel="noreferrer"><FiYoutube /></a></li>        
            <li><a href="https://www.linkedin.com/company/deluxeedits/?viewAsMember=true" target="_blank" rel="noreferrer"><FiLinkedin /></a></li>
        </ul>
    )
}

export default OurSocials;