import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Copyright from '../common/footer/Copyright';
import ServiceOne from '../elements/service/ServiceOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import AboutTwo from "../elements/about/AboutTwo";
import CounterUpTwo from "../elements/counterup/CounterUpTwo";

import Separator from "../elements/separator/Separator";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import Mission from "../elements/about/Mission";
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';


const Home = () => {
    return (
        <>
            <SEO title="Professional Video Editing Company" />
            <main className="page-wrapper">
                
                <div className="header-transparent-with-topbar">
                    {/* <HeaderTopBar /> */}
                    <HeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-not-transparent" />
                </div>

                {/* Start Slider area  */}
                <div className="slider-area slider-style-2 height-950 bg_image" data-black-overlay="2" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/pink_lady.png)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h4 className="subtitle"><span className="theme-gradient">VIDEO EDITING COMPANY</span></h4>
                                    <h1 className="dancing-font">The quality you deserve</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider area  */}


                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Why should you consider us"
                                        title = "Are you tired of video editors <br/> who do not understand you needs?"
                                        description = ""
                                     />
                                </div>
                            </div>
                            <ServiceOne 
                                serviceStyle = "service__style--1 icon-circle-style"
                                textAlign = "text-center"
                             />
                        </div>
                    </div>
                {/* End Service Area  */}

                <Separator />

                <AboutTwo />

                <Separator />
                {/* Start Elements Area  */}
                <div className="rwt-counterup-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Deluxe Edits In Numbers"
                                    title = "Statistics"
                                    description = ""
                                />
                            </div>
                        </div>
                        <CounterUpTwo column="col-lg-4 col-md-6 col-sm-6 col-12" counterStyle="counter-style-2" textALign="text-center" />     
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator /> 
                {/* Start Mission Area   */}
                <Mission />                    
                {/* Start Mission Area  */}


                <Separator />                               
                {/* Start Testimonial Area  */}
                <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What they say about us"
                                    title = "Testimonials"
                                    description = ""
                                />
                            </div>
                        </div>
                        <TestimonialThree teamStyle="" />
                    </div>
                </div>
                {/* End Testimonial Area  */}

                <Separator />
                {/* Start Elements Area  */}

                {/* End Elements Area  */}

                {/* Start Call To Action Area  */}
                <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="wrapper">
                        <CalltoActionFive />
                    </div>
                </div>
                {/* End Call To Action Area  */}

                <Copyright />
            </main>
        </>
    )
}

export default Home;
