import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from "react-on-screen";

const Data = [
    {
        countNum : 65,
        countTitle: 'Happy Clients',
        description: 'People who believe we are the right choice and keep working with us!',
    },
    {
        countNum : 16,
        countTitle: 'Employees',
        description: 'People who love creating awesome content and are willing to sacrafice some sleep when deadlines must be met!',
    },
    {
        countNum : 854,
        countTitle: 'Videos Edited',
        description: 'Videos provided for clients and businesses around the world!',
    },
];
const CounterUpTwo = ({textALign, counterStyle, column}) => {
    return (
        <div className="row">
            {Data.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`count-box ${counterStyle} ${textALign}`}>
                        <TrackVisibility once>
                            {({ isVisible }) => isVisible && 
                                <div className="count-number">{isVisible ? <CountUp end={data.countNum} /> : 0}</div>}
                        </TrackVisibility>
                        <h5 className="title">{data.countTitle}</h5>
                        <p className="description">{data.description}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}
export default CounterUpTwo;