import React from 'react';
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [
    {
        form: "USA",
        description: "I have worked with other editors before, but none of them are as high quality as Deluxe Edits. Deluxe Edits is not only incredibly affordable, and quick at editing my videos, but they also are incredibly receptive to any changes I need. I highly recommend Deluxe Edits.",
        name: "Web Dev Simplified",
        subtitle: "Youtuber / Developer",
        image: "wds_testimonial"
    },
    {
        form: "Bulgaria",
        description: "Deluxe Edits are quick, courteous and very helpful. They truly care about my channel and are constantly looking for ways to improve my content. I am incredibly satisfied with Deluxe Edits!",
        name: "Millionaire Lifestyle",
        subtitle: "Youtuber",
        image: "mill_lifestyle_logo"
    },
    {
        form: "United Kingdom",
        description: "Fast, creative and professional. It is a real pleasure working with Deluxe Edits.",
        name: "Simplistic Digital",
        subtitle: "Digital Agency",
        image: "simplistic_digital_testmonial"
    },
]
const TestimonialThree = ({teamStyle}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                                    <div className="content mt_sm--40">
                                        <span className="form">{data.form}</span>
                                        <p className="description">{data.description}</p>
                                        <div className="client-info">
                                            <h4 className="title">{data.name}</h4>
                                            <h6 className="subtitle">{data.subtitle}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/testimonial/${data.image}.jpg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default TestimonialThree;




















