import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight } from 'react-icons/fi';

const ServiceList = [
    {
        image: '/images/service/icon-01.png',
        title: 'Planner',
        description: 'For creators working with us long term we have a planner where we schedule all of the upload, delivery and release dates for your content.'
    },
    {
        image: '/images/service/icon-02.png',
        title: 'Slack Workspace',
        description: 'You will be invited into a private slack workspace to ensure that you have communication with us at all times.'
    },
    {
        image: '/images/service/icon-03.png',
        title: 'Branding',
        description: 'Your videos will be edited in accordance with your brand(color palette, fonts, etc) to ensure consistency across all platforms.'
    },
    {
        image: '/images/service/icon-04.png',
        title: 'Feedback & Revisions',
        description: 'We will use an industry-leading software - Frame.io to make getting your feedback incredibly simple and fast.'
    }
]
const ServiceThree = ({textAlign, serviceStyle}) => {
    return (
        <>
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <img src={`${val.image}`} alt="card Images" />
                                </div>
                                <div className="content">
                                    <h4 className="title"><span dangerouslySetInnerHTML={{__html: val.title}}></span></h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
        <div className={`service ${textAlign}`}>
        <ScrollAnimation 
        animateIn="fadeInUp"
        animateOut="fadeInOut"
        animateOnce={true}>
            <div className="read-more-btn mt--40">
                <a className="btn-default btn-icon" href="https://calendly.com/deluxeedits/meetings" target="_blank" rel="noreferrer">Work With Us<i className="icon"><FiArrowRight /></i></a>
            </div>
        </ScrollAnimation>
        </div>
        </>
    )
}
export default ServiceThree;