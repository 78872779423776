import PropTypes from "prop-types";
import React from 'react'


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title} | Deluxe Edits</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content="Deluxe Edits - a video editing services company. Focus on your business or yourself and let us handle the editing of your content for you. Get 10% off your first order, 3 rounds of free revisions." />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


