import React from 'react';
import Slider from "react-slick";
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import CircleProgress from "../elements/progressbar/CircleProgress";
import ServiceThree from "../elements/service/ServiceThree";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import BlogClassicData from '../data/blog/BlogList.json';
import PricingTwo from '../elements/pricing/PricingTwo';
import Copyright from '../common/footer/Copyright';
import AccordionOne from '../elements/accordion/AccordionOne';

var BlogListData = BlogClassicData.slice(0, 3);

const BannerData = [
    {
        image: "/images/bg/bg-image-12.jpg",
        title: "Services",
        description: "We help our clients succeed by creating high-quality, <br /> engaging and creative videos"
    },
]


const Services = () => {
    return (
        <>
            <SEO title="Video Editing Services" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <Slider className="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow" {...BannerActivation}>
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="height-950 bg-overlay bg_image" style={{backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`}}>
                                <div className="container">
                                    <div className="row row--30 align-items-center">
                                        <div className="col-lg-12">
                                            <div className="inner text-center">
                                                <h1 className="dancing-font" dangerouslySetInnerHTML={{__html: data.title}}></h1>
                                                <p className="description" dangerouslySetInnerHTML={{__html: data.description}}></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                {/* End Slider Area  */}


                <Separator />
                <div className="rwt-elements-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--25">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we bring to the table"
                                    title = "Our Skillset"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="col-lg-10 offset-lg-1 mt--10">
                            <CircleProgress />
                        </div>
                    </div>
                </div>
                
                <Separator/>
                <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "How we take care of you"
                                        title = "Benefits of working with us"
                                        description = ""
                                     />
                                </div>
                            </div>
                            <ServiceThree 
                                serviceStyle = "service__style--2"
                                textAlign = "text-center"
                             />
                        </div>
                    </div>
                
                    <Separator/>
                    <div className="rwt-pricing-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40 mb_sm--0">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "What we can do for you"
                                            title = "Editing options"
                                            description = ""
                                        />
                                </div>
                            </div>
                            <PricingTwo />
                        </div>
                    </div>


                <Separator />
                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "How we do it"
                                        title = "Working process"
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 mt--50">
                                <TimelineTwo classVar="no-gradient"  />
                            </div>
                        </div>
                    </div>
                </div>


                <Separator />
                
                <div className="rwt-pricing-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40 mb_sm--0">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "FAQ"
                                            title = "Got a Question? These might help"
                                            description = ""
                                        />
                                </div>
                            </div>
                            <AccordionOne customStyle="" />
                        </div>
                    </div>
                


                <Separator />
                <Copyright/>
            </main>
        </>
    )
}

export default Services;
