import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: '/images/service/videoediting.png',
        title: 'Video Editing',
        description: 'Taking videos apart and putting them together, improving the sound and the density of the picture.'
    },
    {
        image: '/images/service/design.png',
        title: 'Design & Creative',
        description: 'Designing and creating custom features for your videos such as transitions, endscreens, etc.'
    },
    {
        image: '/images/service/animation.png',
        title: 'Animation',
        description: 'Slick and modern animations to make your video more interactive and increase your watch time.'
    },
    {
        image: '/images/service/videoeffects.png',
        title: 'Special Effects',
        description: 'Make your videos stand out from the crowd - from simple particles to Marvel-like effects - we do it all!'
    }
]
const ServiceTwo = ({textAlign, cardStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`card-box ${cardStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <Link to="#service">
                                        <img src={`${val.image}`} alt="card Images" />
                                    </Link>
                                </div>
                                <div className="content">
                                    <h4 className="title mb--20"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceTwo;