import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({ customStyle }) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="1">
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        How do I know if I’m ready for a professional video editing service?
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>This is a really personal decision but usually if you already have a business that is doing well or you’ve managed to turn YouTube into a viable income stream then it’s probably time to outsource.<br /><br />

                        You need to protect your energy and focus on activities that will directly impact your revenue. But at the same time you need your videos to reflect your growth and value as a business - which is where we step in!
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        How to get started?
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>Thank you for your interest. If you wish to start working with us simply press one of the "Work with us" or "Go Deluxe" buttons<br />
                        They will take you to our metting scheduler where you will be prompt to answer a few questions about your editing needs<br />
                        and schedule a call with us to
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="6">
                        What is the pricing?
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                    <Card.Body>The price ranges just like the time frame. The things on which we estimate our pricing is:<br />
                        - length of raw footage<br />
                        - transitions<br />
                        - color grading<br />
                        - animations
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                        How long does the video editing process take?
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>There are are a lot of variables which need to be known when estimating
                        the time it would take for the video to be edited such as:<br />
                        - type of the video<br />
                        - length of raw footage<br />
                        - complexity<br />
                        When these things are considered the turnaround time will range from 5 to
                        8 business days.
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                        Where can I send my footage?
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>The footage can either be upload to your own cloud service provider or our own, whichever is more comfortable with you. These details will be arranged once we get in touch.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="4">
                        What are the terms of payment?
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                    <Card.Body>We currently accept Paypal, Stripe, Revolut, Bank Transfer and are working on proving more payment methods for you. Payments can be done in both EUR and USD currency.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="5">
                        How many revisions do I have?
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                    <Card.Body>Up to two revisions are included in the price. We will have to charge you extra hours after the second revision.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="7">
                        Do you work with new content creators?
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                    <Card.Body>Of course! We encourage everyone who is interested in our services to get in touch.
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion >
    )
}
export default AccordionOne

