import React from 'react';
import { FiCheck, FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";

const PricingTwo = () => {
    return (
        <>
            <div className="row row--15">

                {/* Start PRicing Table Area  */}
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="rn-pricing style-2">
                        <div className="pricing-table-inner">
                            <div className="pricing-header">
                                <h4 className="title">Commercial Video <br />Editing</h4>
                            </div>
                            <div className="pricing-body">
                                <ul className="list-style--1">
                                    <li><FiCheck /> Commercials</li>
                                    <li><FiCheck /> Music Videos</li>
                                    <li><FiCheck /> Short Films</li>
                                    <li><FiCheck /> Documentaries</li>
                                    <li><FiCheck /> Interviews</li>
                                    <li><FiCheck /> Showreels</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End PRicing Table Area  */}

                {/* Start PRicing Table Area  */}
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="rn-pricing style-2">
                        <div className="pricing-table-inner">
                            <div className="pricing-header">
                                <h4 className="title">Corporate Brand <br />Videos</h4>
                            </div>
                            <div className="pricing-body">
                                <ul className="list-style--1">
                                    <li><FiCheck /> Event Highlights</li>
                                    <li><FiCheck /> Testimonials</li>
                                    <li><FiCheck /> Product & Service Promos</li>
                                    <li><FiCheck /> Real Estate Videos</li>
                                    <li><FiCheck /> Fundraisers</li>
                                    <li><FiCheck /> Behind the Scenes</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End PRicing Table Area  */}

                {/* Start PRicing Table Area  */}
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="rn-pricing style-2">
                        <div className="pricing-table-inner">
                            <div className="pricing-header">
                                <h4 className="title">Videos & Ads for Social Media</h4>
                            </div>
                            <div className="pricing-body">
                                <ul className="list-style--1">
                                    <li><FiCheck /> YouTube </li>
                                    <li><FiCheck /> IGTV</li>
                                    <li><FiCheck /> Instagram</li>
                                    <li><FiCheck /> Facebook</li>
                                    <li><FiCheck /> Tiktok</li>
                                    <li><FiCheck /> LinkedIn</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End PRicing Table Area  */}

                {/* Start PRicing Table Area  */}
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="rn-pricing style-2">
                        <div className="pricing-table-inner">
                            <div className="pricing-header">
                                <h4 className="title">Educational Videos</h4>
                            </div>
                            <div className="pricing-body">
                                <ul className="list-style--1">
                                    <li><FiCheck /> Online Courses </li>
                                    <li><FiCheck /> Masterclasses</li>
                                    <li><FiCheck /> Workshops</li>
                                    <li><FiCheck /> Conferences</li>
                                    <li><FiCheck /> In-house Trainings</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End PRicing Table Area  */}

                <div className="col-lg-4 col-md-6 col-12">
                    <div className="rn-pricing style-2">
                        <div className="pricing-table-inner">
                            <div className="pricing-header">
                                <h4 className="title">Gaming Videos</h4>
                            </div>
                            <div className="pricing-body">
                                <ul className="list-style--1">
                                    <li><FiCheck /> Gameplay Videos </li>
                                    <li><FiCheck /> Gaming Series</li>
                                    <li><FiCheck /> Twitch Highlights</li>
                                    <li><FiCheck /> Twitch Streams</li>
                                    <li><FiCheck /> Shorts</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End PRicing Table Area  */}

                {/* Start Pricing Table Area  */}
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="rn-pricing style-2">
                        <div className="pricing-table-inner">
                            <div className="pricing-header">
                                <h4 className="title">Vlogs & Events Videos</h4>
                            </div>
                            <div className="pricing-body">
                                <ul className="list-style--1">
                                    <li><FiCheck /> Vlogs </li>
                                    <li><FiCheck /> Weddings </li>
                                    <li><FiCheck /> Theaters & Concerts</li>
                                    <li><FiCheck /> Sport Events</li>
                                    <li><FiCheck /> Memorable Experiences</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Pricing Table Area  */}
            </div>

            <div className="text-center mt--20">
                <p>If you have another type of video for your business that needs to be edited, feel free to get in touch with us.</p>
                <div className="read-more-btn mt--30">
                    <a className="btn-default btn-icon" href="https://calendly.com/deluxeedits/meetings" target="_blank" rel="noreferrer">Work With Us<i className="icon"><FiArrowRight /></i></a>
                </div>
            </div>
        </>

    )
}
export default PricingTwo;