import React from 'react';
import { FiBarChart, FiCast, FiClock, FiPlayCircle, FiArrowRight } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiPlayCircle />,
        title: '',
        description: 'Are your videos lacking in quality?'
    },
    {
        icon: <FiCast />,
        title: '',
        description: 'Are you sick of poor communication and lack of feedback?'
    },
    {
        icon: <FiBarChart />,
        title: '',
        description: 'Are you annoyed by editors who do not care about your business and vision?'
    },
    {
        icon: <FiClock />,
        title: '',
        description: 'Are you frustrated with getting your videos consistently delayed?'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <>
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 services-subtitle-text mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
        <div className={`service ${textAlign}`}>
        <ScrollAnimation 
        animateIn="fadeInUp"
        animateOut="fadeInOut"
        animateOnce={true}>
            <div className="read-more-btn mt--40">
                <Link className="btn-default btn-icon" to="/services">Let Us Help<i className="icon"><FiArrowRight /></i></Link>
            </div>
        </ScrollAnimation>
        </div>
        </>
    )
}
export default ServiceOne;