import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import PortfolioOne from "./PortfolioOne";
import ProgressbarThree from '../progressbar/ProgressbarThree';
import Separator from '../separator/Separator';
import CalltoActionFive from '../calltoaction/CalltoActionFive';

const PortfolioBoxLayout = () => {
    return (
        <>
            <SEO title="Video Editing Portfolio" />
            <Layout>
                <BreadcrumbOne 
                    title="Recipe for success: We handle the editing, <br /> you focus on business"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Portfolio"
                />

                <div className="main-content rn-section-gap">
                    <div className="rwt-elements-area">
                        <div className="container-fluid plr--30">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Portfolio"
                                        title = "View our previous work"
                                        description = ""
                                    />
                                </div>

                            <PortfolioOne Column="col-lg-4 col-md-6 mt--30 box-grid-layout no-overlay" />
                        </div>
                    </div>
                </div>
                <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="wrapper">
                        <CalltoActionFive />
                    </div>
                </div>
                
            </Layout>
        </>
    )
}
export default PortfolioBoxLayout;