import React from 'react';
import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AccordionOne from '../accordion/AccordionOne';

const mission = () => {
    return (
        <div className="rn-company-mission-are rn-section-gap">
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-6">
                        <div className="mission-title">
                            <h2 className="title">Unique Mission<br /> {" "}
                                <Typed className="theme-gradient"
                                    strings={[
                                        "Create",
                                        "Awesome",
                                        "Videos",
                                    ]}
                                    typeSpeed={80}
                                    backSpeed={5}
                                    backDelay={1000}
                                    loop
                                />
                            </h2>
                            <p>Our main goal is to make videos as good as we possibly can! Other than that tho we want to be seen as a reliable and trustworthy company where you can get amazing editing services at a fair price.</p>

                            <p>We want to make video editing simple and easy for our clients. We want them to feel heard and understood while working with us and know that we are giving it our all to make their content exceptional!</p>

                            <p>As for our employees we want them to feel happy and fulfilled with the work they are doing here, but also proud to be part of Deluxe Edits!</p>
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/mission.png" alt="About Images" />
                        </div>
                    </div>
                </div>
            </div>                        
        </div>
    )
}
export default mission;
