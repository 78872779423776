import { directive } from "@babel/types"

export const YoutubeVideo = ({embedId}) => (
        <div className="youtube-responsive">
            <iframe
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Youtube edited video">
            </iframe>
        </div>
)
