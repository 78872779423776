import Copyright from "../../common/footer/Copyright";
import HeaderOne from "../../common/header/HeaderOne";
import SEO from "../../common/SEO";
import Separator from "../separator/Separator";

const TermsAndConditions = () => {
    return (
        <>
            <SEO title="Terms And Conditions" />
            <main className="page-wrapper">
                <div className="header-transparent-with-topbar">
                    {/* <HeaderTopBar /> */}
                    <HeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-not-transparent" />
                </div>

                {/* Main Content */}
                <div className="container">

                    {/* Start Section */}
                    <div className="rn-section-gap">
                        <h1>Terms and Conditions of Deluxe Edits Ltd</h1>
                        <p>These Terms govern</p>
                        <ul className="ml--45 list-icon">
                            <li>
                                the use of this Website, and,
                            </li>
                            <li>
                                any other related Agreement or legal relationship with the Owner
                                in a legally binding way. Capitalized words are defined in the relevant dedicated section of this document.
                            </li>
                        </ul>
                        <p>The User must read this document carefully. <br />
                            This Website is provided by: <br />
                            Deluxe Edits Ltd <br />
                            <p><b>Owner contact email</b>: contact@deluxeedits.com</p>
                            "This Website" refers to <br />
                            <ul className="ml--45 list-icon">
                                <li>
                                    this website, including its subdomains and any other website through which the Owner makes its Service available;
                                </li>
                                <li>
                                    the Service;
                                </li>
                                <li>
                                    any applications, sample and content files, source code, scripts, instruction sets or software included as part of the Service, as well as any related documentation;
                                </li>
                                <li>
                                    Digital Products
                                </li>
                            </ul>
                            The following documents are incorporated by reference into these Terms:
                        </p>
                        <h3>
                            What the User should know at a glance
                        </h3>
                        <ul className="ml--45 list-icon">
                            <li>
                                Please note that some provisions in these Terms may only apply to certain categories of Users. In particular, certain provisions may only apply to Consumers or to those Users that do not qualify as Consumers. Such limitations are always explicitly mentioned within each affected clause. In the absence of any such mention, clauses apply to all Users.
                            </li>
                            <li>
                                Usage of this Website and the Service is age restricted: to access and use this Website and its Service the User must be an adult under applicable law.
                            </li>
                            <li>
                                The right of withdrawal on this Website applies to all Users, including those Users that do not qualify as Consumers. The right of withdrawal, also commonly called the right of cancellation in the UK, is consistently referred to as “the right of withdrawal” within this document.
                            </li>
                        </ul>
                    </div>
                    {/* End Section */}

                    <Separator />

                    {/* Start Section */}
                    <div className="rn-section-gap">
                        <h2>TERMS OF USE</h2>
                        <p>
                            Unless otherwise specified, the terms of use detailed in this section apply generally when using this Website.
                            Single or additional conditions of use or access may apply in specific scenarios and in such cases are additionally indicated within this document.
                            By using this Website, Users confirm to meet the following requirements:
                        </p>
                        <ul className="ml--45 list-icon">
                            <li>
                                There are no restrictions for Users in terms of being Consumers or Business Users;
                            </li>
                            <li>
                                Users must be recognized as adult by applicable law;
                            </li>
                        </ul>
                        <h3>
                            Content on this Website
                        </h3>
                        <p>
                            Unless where otherwise specified or clearly recognizable, all content available on this Website is owned or provided by the Owner or its licensors.
                            The Owner undertakes its utmost effort to ensure that the content provided on this Website infringes no applicable legal provisions or third-party rights. However, it may not always be possible to achieve such a result.
                            In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are kindly asked to preferably report related complaints using the contact details provided in this document.
                        </p>
                        <h3>
                            Rights regarding content on this Website - All rights reserved
                        </h3>
                        <p>
                            The Owner holds and reserves all intellectual property rights for any such content.
                            Users may not therefore use such content in any way that is not necessary or implicit in the proper use of the Service.
                            In particular, but without limitation, Users may not copy, download, share (beyond the limits set forth below), modify, translate, transform, publish, transmit, sell, sublicense, edit, transfer/assign to third parties or create derivative works from the content available on this Website, nor allow any third party to do so through the User or their device, even without the User's knowledge.
                            Where explicitly stated on this Website, the User may download, copy and/or share some content available through this Website for its sole personal and non-commercial use and provided that the copyright attributions and all the other attributions requested by the Owner are correctly implemented.
                            Any applicable statutory limitation or exception to copyright shall stay unaffected.
                        </p>
                        <h3>
                            Access to external resources
                        </h3>
                        <p>
                            Through this Website Users may have access to external resources provided by third parties. Users acknowledge and accept that the Owner has no control over such resources and is therefore not responsible for their content and availability.
                            Conditions applicable to any resources provided by third parties, including those applicable to any possible grant of rights in content, result from each such third parties’ terms and conditions or, in the absence of those, applicable statutory law.
                        </p>
                        <h3>
                            Acceptable use
                        </h3>
                        <p>
                            This Website and the Service may only be used within the scope of what they are provided for, under these Terms and applicable law.
                            Users are solely responsible for making sure that their use of this Website and/or the Service violates no applicable law, regulations or third-party rights.
                            Therefore, the Owner reserves the right to take any appropriate measure to protect its legitimate interests including by denying Users access to this Website or the Service, terminating contracts, reporting any misconduct performed through this Website or the Service to the competent authorities – such as judicial or administrative authorities - whenever Users engage or are suspected to engage in any of the following activities:
                        </p>
                        <ul className="ml--45 list-icon">
                            <li>
                                violate laws, regulations and/or these Terms;
                            </li>
                            <li>
                                infringe any third-party rights;
                            </li>
                            <li>
                                considerably impair the Owner’s legitimate interests;
                            </li>
                            <li>
                                offend the Owner or any third party.
                            </li>
                        </ul>
                        <h3>
                            “Tell-a-friend”
                        </h3>
                        <p>
                            This Website gives Users the opportunity to receive advantages if, as a result of their recommendation, any new User purchases a Product offered on this Website.
                            In order to take advantage of this offer, Users may invite others to purchase the Products on this Website by sending them a tell-a-friend code provided by the Owner. Such codes can only be redeemed once.
                            If upon purchase of the Products on this Website any of the persons invited redeems a tell-a-friend code, the inviting User shall receive the advantage or benefit (such as: a price reduction, an additional service feature, an upgrade etc.) specified on this Website.
                            Tell-a-friend codes may be limited to specific Products among those offered on this Website.
                            The Owner reserves the right to end the offer at any time at its own discretion.
                            While no general limitation applies to the number of persons that can be invited, the amount of advantage or benefit that each inviting User can receive, may be limited.
                        </p>
                    </div>
                    {/* End Section */}

                    <Separator />

                    {/* Start Section */}
                    <div className="rn-section-gap">
                        <h2>
                            TERMS AND CONDITIONS OF SALE
                        </h2>

                        <h3>
                            Paid Products
                        </h3>
                        <p>
                            Some of the Products provided on this Website, as part of the Service, are provided on the basis of payment.
                            The fees, duration and conditions applicable to the purchase of such Products are described below and in the dedicated sections of this Website.
                            To purchase Products, the User must register or log into this Website.
                        </p>

                        <h3>
                            Product description
                        </h3>
                        <p>
                            Prices, descriptions or availability of Products are outlined in the respective sections of this Website and are subject to change without notice.
                            While Products on this Website are presented with the greatest accuracy technically possible, representation on this Website through any means (including, as the case may be, graphic material, images, colors, sounds) is for reference only and implies no warranty as to the characteristics of the purchased Product.
                            The characteristics of the chosen Product will be outlined during the purchasing process. <br />
                            <b>The offer of Products is nonbinding. In order to complete the purchase, Users are requested to submit a binding order. Only once such order is accepted is the contract concluded.</b>
                        </p>
                        <h3>
                            Purchasing process
                        </h3>

                        <p>
                            Any steps taken from choosing a Product to order submission form part of the purchasing process.
                            The purchasing process includes these steps:
                        </p>
                        <ul className="ml--45 list-icon">
                            <li>
                                Users must choose the desired Product and verify their purchase selection.
                            </li>
                            <li>
                                After having reviewed the information displayed in the purchase selection, Users may place the order by submitting it.
                            </li>
                        </ul>

                        <h3>
                            Order submission
                        </h3>
                        <p>
                            When the User submits an order, the following applies:
                        </p>
                        <ul className="ml--45 list-icon">
                            <li>
                                Each order submitted constitutes an offer to purchase. The submission of the order creates for the User the obligation to pay the price, taxes and possible further fees and expenses, as specified on the order page.
                            </li>
                            <li>
                                In case the purchased Product requires active input from the User, such as the provision of personal information or data, specifications or special wishes, the order submission creates an obligation for the User to cooperate accordingly.
                            </li>
                            <li>
                                Upon submission of the order, Users will receive a receipt. Unless stated otherwise in the relevant communication, the aforementioned receipt merely indicates reception of the order and does not constitute acceptance of the order.
                            </li>
                        </ul>

                        <h3>
                            Order acceptance
                        </h3>
                        <ul className="ml--45 list-icon">
                            <li>
                                Unless the order receipt expressly includes the acceptance of the order, in which case the contract is therefore then entered into, the purchase contract is entered into at the moment the User receives the communication of order acceptance.
                            </li>
                            <li>
                                Subject to availability and to the Owner’s discretion, the order shall be accepted without undue delay.
                            </li>
                            <li>
                                If the order is not accepted, the Owner shall issue a refund.
                            </li>
                        </ul>
                        <p>
                            The rejection of an order shall not entitle the User to bring any claim against the Owner, including compensation for damages.
                            All notifications related to the described purchasing process shall be sent to the email address provided by the User for such purposes.
                        </p>

                        <h3>
                            Prices
                        </h3>
                        <p>
                            Users are informed during the purchasing process and before order submission, about any fees, taxes and costs (including, if any, delivery costs) that they will be charged.
                            Prices on this Website are displayed:
                        </p>
                        <ul className="ml--45 list-icon">
                            <li>
                                excluding any applicable fees, taxes and costs
                            </li>
                        </ul>
                        <h3>
                            Offers and discounts
                        </h3>
                        <p>
                            The Owner may offer discounts or provide special offers for the purchase of Products. Any such offer or discount shall always be subject to the eligibility criteria and the terms and conditions set out in the corresponding section of this Website.
                            Offers and discounts are always granted at the Owner’s sole discretion.
                            Repeated or recurring offers or discounts create no claim/title or right that Users may enforce in the future.
                            Depending on the case, discounts or offers shall be valid for a limited time only or while stocks last. If an offer or discount is limited by time, the time indications refer to the time zone of the Owner, as indicated in the Owner’s location details in this document, unless otherwise specified.
                        </p>
                        <h3>
                            Methods of payment
                        </h3>
                        <p>
                            Information related to accepted payment methods are made available during the purchasing process.
                            Some payment methods may only be available subject to additional conditions or fees. In such cases related information can be found in the dedicated section of this Website.
                            All payments are independently processed through third-party services. Therefore, this Website does not collect any payment information – such as credit card details – but only receives a notification once the payment has been successfully completed.
                            If payment through the available methods fail or is refused by the payment service provider, the Owner shall be under no obligation to fulfil the purchase order. Any possible costs or fees resulting from the failed or refused payment shall be borne by the User.
                        </p>
                        <h3>
                            Retention of Product ownership
                        </h3>
                        <p>
                            Until payment of the total purchase price is received by the Owner, any Products ordered shall not become the User’s property.
                        </p>
                        <h3>
                            Retention of usage rights
                        </h3>
                        <p>
                            Users do not acquire any rights to use the purchased Product until the total purchase price is received by the Owner.
                        </p>
                        <h3>
                            Contractual right of cancellation
                        </h3>
                        <p>
                            The Owner grants Users a contractual right to cancel the purchase under the terms and conditions described in the relevant section of this Website within 30 days of concluding the contract.
                        </p>
                        <h3>
                            Delivery
                        </h3>
                        <h3>
                            Delivery of digital content
                        </h3>
                        <p>
                            Unless otherwise stated, digital content purchased on this Website is delivered via download on the device(s) chosen by Users.
                            Users acknowledge and accept that in order to download and/or use the Product, the intended device(s) and its respective software (including operating systems) must be legal, commonly used, up-to-date, and consistent with current market-standards.
                            Users acknowledge and accept that the ability to download the purchased Product may be limited in time and space.
                        </p>
                        <h3>
                            Performance of services
                        </h3>
                        <p>
                            The purchased service shall be performed or made available within the timeframe specified on this Website or as communicated before the order submission.
                        </p>
                    </div>

                    <Separator />

                    <div className="rn-section-gap">
                        <h2>
                            User rights
                        </h2>
                        <h3>
                            Right of withdrawal
                        </h3>
                        <p>
                            Unless exceptions apply, the User may be eligible to withdraw from the contract within the period specified below (generally 14 days), for any reason and without justification. Users can learn more about the withdrawal conditions within this section.
                        </p>
                        <h3>
                            Who the right of withdrawal applies to
                        </h3>
                        <p>
                            The right of withdrawal is a right designed for European Consumers in the case of distance contracts (because the User is not able to see or try the Products before closing the contract). Withdrawal from the contract terminates the obligation of the contracting parties to perform the contract.
                            On this Website the right of withdrawal applies to all Users.
                            Unless any exception mentioned below applies, if any, Users have a right to withdraw from the contract within the specified period applicable to their case, for any reason and without justification.
                        </p>
                        <h3>
                            Exercising the right of withdrawal
                        </h3>
                        <p>
                            To exercise their right of withdrawal, Users must send to the Owner an unequivocal statement of their intention to withdraw from the contract.
                            To this end, Users may use the model withdrawal form available from within the “definitions” section of this document. Users are, however, free to express their intention to withdraw from the contract by making an unequivocal statement in any other suitable way. In order to meet the deadline within which they can exercise such right, Users must send the withdrawal notice before the withdrawal period expires.
                            When does the withdrawal period expire?
                        </p>
                        <ul className="ml--45 list-icon">
                            <li>
                                Regarding the purchase of a service,the withdrawal period expires 14 days after the day that the contract is entered into, unless the User has waived the withdrawal right.
                            </li>
                            <li>
                                In case of purchase of a digital content not supplied in a tangible medium,the withdrawal period expires 14 days after the day that the contract is entered into, unless the User has waived the withdrawal right.
                            </li>
                        </ul>
                        <h3>
                            Effects of withdrawal
                        </h3>
                        <p>
                            Users who correctly withdraw from a contract will be reimbursed by the Owner for all payments made to the Owner, including, if any, those covering the costs of delivery.
                            However, any additional costs resulting from the choice of a particular delivery method other than the least expensive type of standard delivery offered by the Owner, will not be reimbursed.
                            Such reimbursement shall be made without undue delay and, in any event, no later than 14 days from the day on which the Owner is informed of the User’s decision to withdraw from the contract. Unless otherwise agreed with the User, reimbursements will be made using the same means of payment as used to process the initial transaction. In any event, the User shall not incur any costs or fees as a result of such reimbursement.
                            <b>…on the purchase of services</b>
                            Where a User exercises the right of withdrawal after having requested that the service be performed before the withdrawal period expires, the User shall pay to the Owner an amount which is in proportion to the part of service provided.
                            Such payment shall be calculated based on the fee contractually agreed upon, and be proportional to the part of service provided until the time the User withdraws, compared with the full coverage of the contract.
                        </p>
                        <h3>
                            Contractual withdrawal period extension
                        </h3>
                        <p>
                            In addition, and without prejudice to the statutory right of withdrawal or any other statutory rights, including guarantee rights, the Owner grants Users a contractual right to withdraw from the contract within 30 days. Users can therefore withdraw from the contract even once the statutory withdrawal period has expired.
                            The contractual extension of the withdrawal period is subject to the same conditions stated for the statutory right of withdrawal, including those for determining the beginning and the end of the withdrawal period.
                        </p>
                        <h3>
                            Liability and indemnification
                        </h3>
                        <h4>
                            Australian Users
                        </h4>
                        <h5>
                            Limitation of liability
                        </h5>
                        <p>
                            Nothing in these Terms excludes, restricts or modifies any guarantee, condition, warranty, right or remedy which the User may have under the Competition and Consumer Act 2010 (Cth) or any similar State and Territory legislation and which cannot be excluded, restricted or modified (non-excludable right). To the fullest extent permitted by law, our liability to the User, including liability for a breach of a non-excludable right and liability which is not otherwise excluded under these Terms of Use, is limited, at the Owner’s sole discretion, to the re-performance of the services or the payment of the cost of having the services supplied again.
                        </p>
                        <h4>
                            US Users
                        </h4>
                        <h5>
                            Disclaimer of Warranties
                        </h5>
                        <p>
                            This Website is provided strictly on an “as is” and “as available” basis. Use of the Service is at Users’ own risk. To the maximum extent permitted by applicable law, the Owner expressly disclaims all conditions, representations, and warranties — whether express, implied, statutory or otherwise, including, but not limited to, any implied warranty of merchantability, fitness for a particular purpose, or non-infringement of third-party rights. No advice or information, whether oral or written, obtained by user from owner or through the Service will create any warranty not expressly stated herein.
                            Without limiting the foregoing, the Owner, its subsidiaries, affiliates, licensors, officers, directors, agents, co-branders, partners, suppliers and employees do not warrant that the content is accurate, reliable or correct; that the Service will meet Users’ requirements; that the Service will be available at any particular time or location, uninterrupted or secure; that any defects or errors will be corrected; or that the Service is free of viruses or other harmful components. Any content downloaded or otherwise obtained through the use of the Service is downloaded at users own risk and users shall be solely responsible for any damage to Users’ computer system or mobile device or loss of data that results from such download or Users’ use of the Service.
                            The Owner does not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the Service or any hyperlinked website or service, and the Owner shall not be a party to or in any way monitor any transaction between Users and third-party providers of products or services.
                            The Service may become inaccessible or it may not function properly with Users’ web browser, mobile device, and/or operating system. The owner cannot be held liable for any perceived or actual damages arising from Service content, operation, or use of this Service.
                            Federal law, some states, and other jurisdictions, do not allow the exclusion and limitations of certain implied warranties. The above exclusions may not apply to Users. This Agreement gives Users specific legal rights, and Users may also have other rights which vary from state to state. The disclaimers and exclusions under this agreement shall not apply to the extent prohibited by applicable law.
                        </p>
                        <h5>
                            Limitations of liability
                        </h5>
                        <p>
                            To the maximum extent permitted by applicable law, in no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for
                        </p>
                        <ul className="ml--45 list-icon">
                            <li>
                                any indirect, punitive, incidental, special, consequential or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data or other intangible losses, arising out of or relating to the use of, or inability to use, the Service; and
                            </li>
                            <li>
                                any damage, loss or injury resulting from hacking, tampering or other unauthorized access or use of the Service or User account or the information contained therein;
                            </li>
                            <li>
                                any errors, mistakes, or inaccuracies of content;
                            </li>
                            <li>
                                personal injury or property damage, of any nature whatsoever, resulting from User access to or use of the Service;
                            </li>
                            <li>
                                personal injury or property damage, of any nature whatsoever, resulting from User access to or use of the Service;
                            </li>
                            <li>
                                any interruption or cessation of transmission to or from the Service;
                            </li>
                            <li>
                                any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Service;
                            </li>
                            <li>
                                any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service; and/or
                            </li>
                            <li>
                                the defamatory, offensive, or illegal conduct of any User or third party. In no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for any claims, proceedings, liabilities, obligations, damages, losses or costs in an amount exceeding the amount paid by User to the Owner hereunder in the preceding 12 months, or the period of duration of this agreement between the Owner and User, whichever is shorter
                            </li>
                        </ul>
                        <p>
                            This limitation of liability section shall apply to the fullest extent permitted by law in the applicable jurisdiction whether the alleged liability is based on contract, tort, negligence, strict liability, or any other basis, even if company has been advised of the possibility of such damage.
                            Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, therefore the above limitations or exclusions may not apply to User. The terms give User specific legal rights, and User may also have other rights which vary from jurisdiction to jurisdiction. The disclaimers, exclusions, and limitations of liability under the terms shall not apply to the extent prohibited by applicable law.
                        </p>
                        <h3>
                            Indemnification
                        </h3>
                        <p>
                            The User agrees to defend, indemnify and hold the Owner and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees harmless from and against any and all claims or demands, damages, obligations, losses, liabilities, costs or debt, and expenses, including, but not limited to, legal fees and expenses, arising from
                        </p>
                        <ul className="ml--45 list-icon">
                            <li>
                                User’s use of and access to the Service, including any data or content transmitted or received by User;
                            </li>
                            <li>
                                User’s violation of these terms, including, but not limited to, User’s breach of any of the representations and warranties set forth in these terms;
                            </li>
                            <li>
                                User’s violation of any third-party rights, including, but not limited to, any right of privacy or intellectual property rights;
                            </li>
                            <li>
                                User’s violation of any statutory law, rule, or regulation;
                            </li>
                            <li>
                                any content that is submitted from User’s account, including third party access with User’s unique username, password or other security measure, if applicable, including, but not limited to, misleading, false, or inaccurate information;
                            </li>
                            <li>
                                User’s wilful misconduct; or
                            </li>
                            <li>
                                statutory provision by User or its affiliates, officers, directors, agents, co-branders, partners, suppliers and employees to the extent allowed by applicable law.
                            </li>
                        </ul>
                    </div>

                    <Separator />

                    <div className="rn-section-gap">
                        <h2>
                            Common provisions
                        </h2>
                        <h3>
                            No Waiver
                        </h3>
                        <p>
                            The Owner’s failure to assert any right or provision under these Terms shall not constitute a waiver of any such right or provision. No waiver shall be considered a further or continuing waiver of such term or any other term.
                        </p>
                        <h3>
                            Service interruption
                        </h3>
                        <p>
                            To ensure the best possible service level, the Owner reserves the right to interrupt the Service for maintenance, system updates or any other changes, informing the Users appropriately.
                            Within the limits of law, the Owner may also decide to suspend or terminate the Service altogether. If the Service is terminated, the Owner will cooperate with Users to enable them to withdraw Personal Data or information in accordance with applicable law.
                            Additionally, the Service might not be available due to reasons outside the Owner’s reasonable control, such as “force majeure” (eg. labor actions, infrastructural breakdowns or blackouts etc).
                        </p>
                        <h3>
                            Service reselling
                        </h3>
                        <p>
                            Users may not reproduce, duplicate, copy, sell, resell or exploit any portion of this Website and of its Service without the Owner’s express prior written permission, granted either directly or through a legitimate reselling programme.
                        </p>
                        <h3>
                            Privacy policy
                        </h3>
                        <p>
                            To learn more about the use of their Personal Data, Users may refer to the privacy policy of this Website.
                        </p>
                        <h3>
                            Intellectual property rights
                        </h3>
                        <p>
                            Without prejudice to any more specific provision of these Terms, any intellectual property rights, such as copyrights, trademark rights, patent rights and design rights related to this Website are the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties relating to intellectual property.
                            All trademarks — nominal or figurative — and all other marks, trade names, service marks, word marks, illustrations, images, or logos appearing in connection with this Website are, and remain, the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties related to intellectual property.

                        </p>
                        <h3>
                            Changes to these Terms
                        </h3>
                        <p>
                            The Owner reserves the right to amend or otherwise modify these Terms at any time. In such cases, the Owner will appropriately inform the User of these changes.
                            Such changes will only affect the relationship with the User for the future.
                            The continued use of the Service will signify the User’s acceptance of the revised Terms. If Users do not wish to be bound by the changes, they must stop using the Service. Failure to accept the revised Terms, may entitle either party to terminate the Agreement.
                            The applicable previous version will govern the relationship prior to the User's acceptance. The User can obtain any previous version from the Owner.

                        </p>
                        <h3>
                            Assignment of contract
                        </h3>
                        <p>
                            The Owner reserves the right to transfer, assign, dispose of by novation, or subcontract any or all rights or obligations under these Terms, taking the User’s legitimate interests into account. Provisions regarding changes of these Terms will apply accordingly.
                            Users may not assign or transfer their rights or obligations under these Terms in any way, without the written permission of the Owner.

                        </p>
                        <h3>
                            Contacts
                        </h3>
                        <p>
                            All communications relating to the use of this Website must be sent using the contact information stated in this document.
                        </p>
                        <h3>
                            Severability
                        </h3>
                        <p>
                            Should any provision of these Terms be deemed or become invalid or unenforceable under applicable law, the invalidity or unenforceability of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and effect.
                        </p>
                        <h4>
                            US Users
                        </h4>
                        <p>
                            Any such invalid or unenforceable provision will be interpreted, construed and reformed to the extent reasonably required to render it valid, enforceable and consistent with its original intent. These Terms constitute the entire Agreement between Users and the Owner with respect to the subject matter hereof, and supersede all other communications, including but not limited to all prior agreements, between the parties with respect to such subject matter. These Terms will be enforced to the fullest extent permitted by law.
                        </p>
                        <h4>
                            EU Users
                        </h4>
                        <p>
                            Should any provision of these Terms be or be deemed void, invalid or unenforceable, the parties shall do their best to find, in an amicable way, an agreement on valid and enforceable provisions thereby substituting the void, invalid or unenforceable parts.
                            In case of failure to do so, the void, invalid or unenforceable provisions shall be replaced by the applicable statutory provisions, if so permitted or stated under the applicable law.
                            Without prejudice to the above, the nullity, invalidity or the impossibility to enforce a particular provision of these Terms shall not nullify the entire Agreement, unless the severed provisions are essential to the Agreement, or of such importance that the parties would not have entered into the contract if they had known that the provision would not be valid, or in cases where the remaining provisions would translate into an unacceptable hardship on any of the parties.

                        </p>
                        <h3>
                            Authoritative version of these Terms
                        </h3>
                        <p>
                            These Terms are drawn up and revised in English. Other language versions of these Terms are provided for information purposes only. In the event of any inconsistency between different linguistic versions, the original version shall always prevail.
                        </p>
                        <h3>
                            Governing law
                        </h3>
                        <p>
                            These Terms are governed by the law of the place where the Owner is based, as disclosed in the relevant section of this document, without regard to conflict of laws principles.
                        </p>
                        <h4>
                            Exception for European Consumers
                        </h4>
                        <p>
                            However, regardless of the above, if the User qualifies as a European Consumer and has their habitual residence in a country where the law provides for a higher consumer protection standard, such higher standards shall prevail.
                        </p>
                        <h3>
                            Venue of jurisdiction
                        </h3>
                        <p>
                            The exclusive competence to decide on any controversy resulting from or connected to these Terms lies with the courts of the place where the Owner is based, as displayed in the relevant section of this document.
                        </p>
                        <h4>
                            Exception for European Consumers
                        </h4>
                        <p>
                            The above does not apply to any Users that qualify as European Consumers, nor to Consumers based in Switzerland, Norway or Iceland.
                        </p>
                    </div>
                    {/* End Section */}


                    <Separator />

                    {/* Start Section */}

                    <div className="rn-section-gap">
                        <h2>
                            Dispute resolution
                        </h2>
                        <h3>
                            Amicable dispute resolution
                        </h3>
                        <p>
                            Users may bring any disputes to the Owner who will try to resolve them amicably.
                            While Users' right to take legal action shall always remain unaffected, in the event of any controversy regarding the use of this Website or the Service, Users are kindly asked to contact the Owner at the contact details provided in this document.
                            The User may submit the complaint including a brief description and if applicable, the details of the related order, purchase, or account, to the Owner’s email address specified in this document.
                            The Owner will process the complaint without undue delay and within 21 days of receiving it.
                        </p>
                        <h3>
                            Online dispute resolution for Consumers
                        </h3>
                        <p>
                            The European Commission has established an online platform for alternative dispute resolutions that facilitates an out-of-court method for solving any dispute related to and stemming from online sale and service contracts.
                            As a result, any European Consumer can use such platform for resolving any dispute stemming from contracts which have been entered into online. <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage" target="_blank">The platform is available at the following link</a>
                        </p>
                    </div>

                    <Separator />

                    <div className="rn-section-gap">
                        <h2>
                            Definitions and legal references
                        </h2>
                        <h3>
                            This Website (or this Application)
                        </h3>
                        <p>
                            The property that enables the provision of the Service.
                        </p>
                        <h3>
                            Agreement
                        </h3>
                        <p>
                            Any legally binding or contractual relationship between the Owner and the User, governed by these Terms.
                        </p>
                        <h3>
                            Business User
                        </h3>
                        <p>
                            Any User that does not qualify as a Consumer.
                        </p>
                        <h3>
                            European (or Europe)
                        </h3>
                        <p>
                            Applies where a User is physically present or has their registered offices within the EU, regardless of nationality.
                        </p>
                        <h3>
                            Owner (or We)
                        </h3>
                        <p>
                            Indicates the natural person(s) or legal entity that provides this Website and/or the Service to Users.
                        </p>
                        <h3>
                            Product
                        </h3>
                        <p>
                            A good or service available for purchase through this Website, such as e.g. physical goods, digital files, software, booking services etc.

                            The sale of Products may be part of the Service.
                        </p>
                        <h3>
                            Service
                        </h3>
                        <p>
                            The service provided by this Website as described in these Terms and on this Website.
                        </p>
                        <h3>
                            Terms
                        </h3>
                        <p>
                            All provisions applicable to the use of this Website and/or the Service as described in this document, including any other related documents or agreements, and as updated from time to time.
                        </p>
                        <h3>
                            User (or You)
                        </h3>
                        <p>
                            Indicates any natural person or legal entity using this Website.
                        </p>
                        <h3>
                            Consumer
                        </h3>
                        <p>
                            Any User qualifying as a natural person who accesses goods or services for personal use, or more generally, acts for purposes outside their trade, business, craft or profession.
                        </p>
                        <p>Last Update: October 27, 2021</p>
                    </div>
                </div>

                <Copyright />
            </main>
        </>
    )
}

export default TermsAndConditions;