import HeaderOne from "../../common/header/HeaderOne";
import Copyright from "../../common/footer/Copyright";
import Separator from "../separator/Separator";
import SEO from "../../common/SEO";

const PrivacyPolicy = () => {
    return (
        <>
            <SEO title="Privacy Policy" />
            <main className="page-wrapper"></main>
            <div className="header-transparent-with-topbar">
                {/* <HeaderTopBar /> */}
                <HeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-not-transparent" />
            </div>

            <div className="container">
                <div className="rn-section-gap">
                    <h1>Privacy Policy of Deluxe Edits Ltd</h1>
                    <p>This Website collects some Personal Data from its Users.<br /><br /><br />
                        This document contains a section dedicated to Californian consumers and their privacy rights.<br /><br />
                        This document can be printed for reference by using the print command in the settings of any browser.
                    </p>

                    <h2>Owner and Data Controller</h2>
                    <p>Deluxe Edits Ltd</p>
                    <p><b>Owner contact email</b>: contact@deluxeedits.com</p>

                    <h2>Types of Data collected</h2>
                    <p>
                        Among the types of Personal Data that this Website collects, by itself or through third parties, there are: email address; Usage Data; first name; last name; geographic position; country; various types of Data; field of activity.<br />
                        Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.<br />
                        Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Website.<br />
                        Unless specified otherwise, all Data requested by this Website is mandatory and failure to provide this Data may make it impossible for this Website to provide its services. In cases where this Website specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.<br />
                        Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.<br /><br />

                        Users are responsible for any third-party Personal Data obtained, published or shared through this Website and confirm that they have the third party's consent to provide the Data to the Owner.

                    </p>
                </div>

                <Separator />

                <div className="rn-section-gap">
                    <h2>Mode and place of processing the Data</h2>
                    <h3>Methods of processing</h3>
                    <p>The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.
                        The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Website (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.
                    </p>
                    <h3>
                        Legal basis of processing
                    </h3>
                    <p>The Owner may process Personal Data relating to Users if one of the following applies:</p>
                    <ul className="list-icon ml--45">
                        <li>1. Users have given their consent for one or more specific purposes. Note: Under some legislations the Owner may be allowed to process Personal Data until the User objects to such processing (“opt-out”), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to European data protection law;</li>
                        <li>2. Provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;</li>
                        <li>3. Processing is necessary for compliance with a legal obligation to which the Owner is subject;</li>
                        <li>4. Processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner;</li>
                        <li>5. Processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party.</li>
                    </ul>
                    <p>In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</p>

                    <h3>
                        Place
                    </h3>
                    <p>
                        The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located.
                    </p>
                    <p>
                        Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data.
                    </p>
                    <p>
                        Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.
                    </p>
                    <p>
                        If any such transfer takes place, Users can find out more by checking the relevant sections of this document or inquire with the Owner using the information provided in the contact section.
                    </p>

                    <h3>
                        Retention Time
                    </h3>
                    <p>Personal Data shall be processed and stored for as long as required by the purpose they have been collected for.</p>
                    <p>Therefore:</p>
                    <ul className="list-icon ml--45">
                        <li>1. Personal Data collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed.</li>
                        <li>2. Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of this document or by contacting the Owner.</li>
                    </ul>
                    <p>
                        The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.
                    </p>
                    <p>
                        Once the retention period expires, Personal Data shall be deleted. Therefore, the right of access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.
                    </p>
                </div>

                <div>
                    <h3>
                        The purposes of processing
                    </h3>
                    <p>
                        The Data concerning the User is collected to allow the Owner to provide its Service, comply with its legal obligations, respond to enforcement requests, protect its rights and interests (or those of its Users or third parties), detect any malicious or fraudulent activity, as well as the following: User database management, Analytics, Managing data collection and online surveys, Interaction with data collection platforms and other third parties, Platform services and hosting, Contacting the User and Displaying content from external platforms.
                    </p>
                    <p>
                        For specific information about the Personal Data used for each purpose, the User may refer to the section “Detailed information on the processing of Personal Data”.
                    </p>

                    <h3>
                        Detailed information on the processing of Personal Data
                    </h3>
                    <p>Personal Data is collected for the following purposes and using the following services:</p>
                    <h4>
                        Analytics
                    </h4>
                    <p>
                        The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.
                    </p>
                    <h4>
                        Contacting the User
                    </h4>
                    <h5>
                        Mailing list or newsletter (this Website)
                    </h5>
                    <p>
                        By registering on the mailing list or for the newsletter, the User’s email address will be added to the contact list of those who may receive email messages containing information of commercial or promotional nature concerning this Website. Your email address might also be added to this list as a result of signing up to this Website or after making a purchase.
                    </p>
                    <p>
                        Personal Data processed: country; email address; first name; Usage Data.
                    </p>
                    <p>
                        Category of personal information collected according to CCPA: identifiers; internet information.
                    </p>

                    <h5>
                        Contact form (this Website)
                    </h5>

                    <p>
                        By filling in the contact form with their Data, the User authorizes this Website to use these details to reply to requests for information, quotes or any other kind of request as indicated by the form’s header.
                    </p>
                    <p>
                        Personal Data processed: country; email address; field of activity; first name; various types of Data.
                    </p>
                    <p>
                        Category of personal information collected according to CCPA: identifiers; internet information.
                    </p>

                    <h4>
                        Displaying content from external platforms
                    </h4>

                    <p>
                        This type of service allows you to view content hosted on external platforms directly from the pages of this Website and interact with them.
                        This type of service might still collect web traffic data for the pages where the service is installed, even when Users do not use it.
                    </p>

                    <h4>
                        Google Fonts (Google LLC)
                    </h4>

                    <p>
                        Google Fonts is a typeface visualization service provided by Google LLC that allows this Website to incorporate content of this kind on its pages.
                    </p>
                    <p>
                        Personal Data processed: Usage Data; various types of Data as specified in the privacy policy of the service.
                    </p>
                    <p>
                        Place of processing: United States – Privacy Policy.
                    </p>
                    <p>
                        Category of personal information collected according to CCPA: internet information.
                    </p>

                    <h4>
                        Interaction with data collection platforms and other third parties
                    </h4>
                    <p>
                        This type of service allows Users to interact with data collection platforms or other services directly from the pages of this Website for the purpose of saving and reusing data.
                    </p>
                    <p>
                        If one of these services is installed, it may collect browsing and Usage Data in the pages where it is installed, even if the Users do not actively use the service.
                    </p>

                    <h4>
                        Managing data collection and online surveys
                    </h4>
                    <p>
                        This type of service allows this Website to manage the creation, deployment, administration, distribution and analysis of online forms and surveys in order to collect, save and reuse Data from any responding Users.
                        The Personal Data collected depend on the information asked and provided by the Users in the corresponding online form.
                    </p>
                    <p>
                        These services may be integrated with a wide range of third-party services to enable the Owner to take subsequent steps with the Data processed - e.g. managing contacts, sending messages, analytics, advertising and payment processing.
                    </p>

                    <h4>
                        Platform services and hosting
                    </h4>
                    <p>
                        These services have the purpose of hosting and running key components of this Website, therefore allowing the provision of this Website from within a unified platform. Such platforms provide a wide range of tools to the Owner – e.g. analytics, user registration, commenting, database management, e-commerce, payment processing – that imply the collection and handling of Personal Data.
                        Some of these services work through geographically distributed servers, making it difficult to determine the actual location where the Personal Data are stored.
                    </p>
                    <h4>
                        User database management
                    </h4>

                    <p>
                        This type of service allows the Owner to build user profiles by starting from an email address, a personal name, or other information that the User provides to this Website, as well as to track User activities through analytics features. This Personal Data may also be matched with publicly available information about the User (such as social networks' profiles) and used to build private profiles that the Owner can display and use for improving this Website.
                        Some of these services may also enable the sending of timed messages to the User, such as emails based on specific actions performed on this Website.
                    </p>
                </div>

                <Separator />

                <div className="rn-section-gap">
                    <h2>
                        The rights of Users
                    </h2>

                    <p>
                        Users may exercise certain rights regarding their Data processed by the Owner.
                    </p>
                    <p>
                        In particular, Users have the right to do the following:
                    </p>
                    <ul className="list-icon ml--45">
                        <li>
                            1. Withdraw their consent at any time. Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data.
                        </li>
                        <li>
                            2. Object to processing of their Data. Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below.
                        </li>
                        <li>
                            3.	Access their Data. Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.
                        </li>
                        <li>
                            4.	Verify and seek rectification. Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.
                        </li>
                        <li>
                            5.	Restrict the processing of their Data. Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it.
                        </li>
                        <li>
                            6.	Have their Personal Data deleted or otherwise removed. Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.
                        </li>
                        <li>
                            7.	Receive their Data and have it transferred to another controller. Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof.
                        </li>
                        <li>
                            8.	Lodge a complaint. Users have the right to bring a claim before their competent data protection authority.
                        </li>
                    </ul>

                    <h4>
                        Details about the right to object to processing
                    </h4>
                    <p>
                        Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their particular situation to justify the objection.
                    </p>
                    <p>
                        Users must know that, however, should their Personal Data be processed for direct marketing purposes, they can object to that processing at any time without providing any justification. To learn, whether the Owner is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this document.
                    </p>

                    <h4>
                        How to exercise these rights
                    </h4>
                    <p>
                        Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month.
                    </p>
                </div>
            </div>

            <Copyright />
        </>
    )
}

export default PrivacyPolicy;